function LeadsStep3a1() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Potencjalny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Potencjalny Klient - Klient w procesie sprzedaży -
              Klient chce się spotkać
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Dziękuje bardzo, spotkanie zostało zaplanowane, link zostanie
              przesłany automatycznie na Pani/Pana adres mailowy. Wcześniej otrzyma
              Pani/Pan wiadomość a agendą oraz kilkoma informacjami, które pozwolą
              na efektywne przeprowadzenie spotkania.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://outlook.office365.com/owa/calendar/FilipKaniaiFilGroup@ifil.pl/bookings/"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep3a1;
