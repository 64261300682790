function LeadsStep3b3() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Potencjalny Klient</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Potencjalny Klient - Nowy Klient - Klient uzupełni
          formularz samodzielnie
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic max-w-[800px] mx-auto">
          Oczywiście, w takim razie proszę wejść na stronę ifil.pl/wycena, znajdzie tam Pani/Pan
          formularz, na bazie którego zostanie przygotowana propozycja
          technologiczna wraz z wyceną i harmonogramem prac. Jeżeli w przeciągu 24 godzin nie otrzymał/aby Pani / Pan oferty na maila - proszę o kontakt. Będzie oznaczało to, że zgłoszenie do nas nie dotarło.
        </p>
      </div>
    </>
  );
}

export default LeadsStep3b3;
