import Content from "../compontents/office/content";
import { StepProvider } from "../context/StepProvider";

function Office() {
  return (
    <div className="w-screen bg-black h-screen flex flex-row items-stretch justify-between relative gap-6">
      <div className="w-full h-full absolute z-20 bg-gradient-to-br from-transparent to-[#6f00d8]/90 opacity-30"></div>
      <div className="w-full z-30 h-full overflow-y-auto">
        <Content />
      </div>
    </div>
  );
}

export default Office;
