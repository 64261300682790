function CustomersStep2d() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Obecny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Obecny Klient - Inny kontakt z pracownikiem
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Poinformuję o kontakcie z Pani / Pana strony. Skontaktuje się on na
              wskazany numer telefonu tak szybko jak to możliwe. Najpóźniej w
              przeciągu 48 godzin.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-6995/CYO3WREXQGO19TV348"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomersStep2d;
