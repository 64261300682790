import React, { createContext, useContext, useState } from "react";

type StepContextType = {
  currentStep: string;
  setStep: (step: string) => void;
};

const StepContext = createContext<StepContextType | undefined>(undefined);

export const StepProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState("step0");

  return (
    <StepContext.Provider value={{ currentStep, setStep: setCurrentStep }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepProvider");
  }
  return context;
};
