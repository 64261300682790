import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Office from "./pages/Office";
import { StepProvider } from "./context/StepProvider";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Przekierowanie z "/" na "/office" */}
          <Route path="/" element={<Navigate to="/office" replace />} />
          <Route path="/tools" element={<Navigate to="/office" replace />} />
          {/* Office route */}
          <Route
            path="/office"
            element={
              <StepProvider>
                <Office />
              </StepProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
