function CustomersStep2a() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Obecny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Obecny Klient - Zmiany w projekcie
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Dziękuję bardzo, zmiana została przekazana do realizacji. Jeżeli
              miałyby wystąpić jakieś przeciwwskazania to Opiekun projektu
              skontaktuje się z Panią/Panem celem przekazania alternatywnego
              rozwiązania. Dyspozycje realizujemy w przeciągu maksymalnie 72 godzin.
              Informacje otrzyma Pani/Pan mailowo
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-4535/WCX3YZ56GKO7S1AXG1"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomersStep2a;
