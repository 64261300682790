function LeadsStep3b1() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Potencjalny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Potencjalny Klient - Nowy Klient - Uzupełniamy teraz
              formularz
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Dziękuję, w takim razie zadam Pani / Panu kilka pytań.<br></br>
              <strong>Zadajemy pytania z formularza</strong>
              Dziękuję za informację, oferta zostanie przygotowana w przeciągu 24 godzin i trafi na Pani / Pan adres mailowy. Jeżeli wiadomość nie dotarłaby w przeciągu wskazanych 24 godzin - proszę o kontakt pod nasz numer telefonu. Będzie to oznaczało, że wiadomość trafiła do SPAM-u bądź została zablokowana przez serwer pocztowy.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-5195/T1KBUKLBCMTPRRP1OH"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep3b1;
