import { useStep } from "../../../context/StepProvider";

function CustomersStep1() {
  const { setStep } = useStep();

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Obecny Klient</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Obecny Klient
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
          Jak mogę Pani/Panu pomóc? Czy zgłoszenie dotyczy zakończonego już
          projektu czy będącego w trakcie realizacji?
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 lg:gap-12">
          <div onClick={() => setStep("customersStep2a")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Zmiany w projekcie
            </p>
            <p className="text-white text-sm opacity-50 italic mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("customersStep2b")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Dostarczenie materiałów
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("customersStep2c")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Stan projektu
            </p>
            <p className="text-white text-sm opacity-50 italic mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("customersStep2d")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Inny kontakt z pracownikiem iFil
            </p>
            <p className="text-white text-sm opacity-50 italic mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Wybierz
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomersStep1;
