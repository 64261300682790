function HrStep1() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Praktyki / praca</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Praktyki / Praca
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
          Obecnie nie prowadzimy niestety rekrutacji. Zarówno na praktyki jak i
          na regularne stanowiska.
        </p>
      </div>
    </>
  );
}

export default HrStep1;
