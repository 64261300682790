function LeadsStep3a4() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Potencjalny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Potencjalny Klient - Klient w procesie sprzedaży -
              Klient chce podpisać umowę
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Dziękuję bardzo, bardzo się cieszymy z tego powodu. Przekażę w związku
              z tym informację do Pani/Pana Opiekuna oraz przygotujemy umowę, która
              trafi na Pani/Pana maila do końca dnia z platformy Autenti. Umożliwi
              ona Pani/Panu podpisanie umowy.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-8095/3FGP8SBBSX4YEF7YZU"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep3a4;
