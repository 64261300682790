import { useStep } from "../../../context/StepProvider";

function LeadsStep2a() {
  const { setStep } = useStep();

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Potencjalny Klient</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Potencjalny Klient - Klient w procesie sprzedaży
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
          Dziękuję za informację, jak mogę Panu pomóc?
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 lg:gap-12">
          <div onClick={() => setStep("leadsStep3a1")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:py-12 lg:px-6 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Klient chce się spotkać
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("leadsStep3a2")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:py-12 lg:px-6 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Klient chce negocjować warunki
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("leadsStep3a3")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:py-12 lg:px-6 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Kontakt z Opiekunem Projektu
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("leadsStep3a4")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:py-12 lg:px-6 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Klient chce podpisać umowę
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep2a;
