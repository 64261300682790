import { useStep } from "../../../context/StepProvider";

function LeadsStep2b() {
  const { setStep } = useStep();

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Potencjalny Klient</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Potencjalny Klient - Nowy Klient
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
          Jeżeli chodzi generalnie o usługi informatyczne z racji różnych
          technologii mamy wiele możliwości. W związku z tym nasze oferty składają się zwykle z trzech lub czterech wariantów technologicznych. Różnią się one ceną i czasem realizacji oraz oczywiście skutkami wpływającymi na wydajność, pozycjonowanie oraz funkcjonalności. Aby przygotować dla Pani / Pana ofertę musimy poznać odpowiedź na kilka pytań. Mamy trzy opcje, mogę je Pani / Panu zadać teraz, wtedy zgłoszenie trafi do specjalisty, który przygotuje koncepcję wraz z ofertą. Druga opcja to bezpośredni kontakt ze specjalistą, który zbierze od Pani / Pana informacje bezpośrednio i przygotuje ofertę. Trzecia możliwość to samodzielne uzupełnienie formularza wyceny na naszej stronie. Co Pani / Pan preferuje?
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12">
          <div onClick={() => setStep("leadsStep3b1")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Uzupełniamy teraz formularz
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("leadsStep3b2")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Przekaż kontakt do specjalisty
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div onClick={() => setStep("leadsStep3b3")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Klient uzupełni formularz samodzielnie
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep2b;
