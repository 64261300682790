function LeadsStep3a3() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Potencjalny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Potencjalny Klient - Klient w procesie sprzedaży -
              Kontakt z opiekunem projektu
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Przekażę tę informację do Pani/Pana Opiekuna, który zajmuje się całą
              współpracą. Pan Filip skontaktuje się z Panią/Panem tak szybko jak to
              możliwe.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-8095/3FGP8SBBSX4YEF7YZU"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep3a3;
