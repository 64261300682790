function LeadsStep3b2() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="text-4xl text-white">Potencjalny Klient</h2>
            <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
              Nowe połączenie - Potencjalny Klient - Nowy Klient - Przekaż kontakt
              do specjalisty
            </p>
            <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
              Dziękuję, w takim razie przekażę informację do naszego zespołu. Specjalista skontaktuje się z Panią / Panem w przeciągu 24 godzin (w dni robocze).
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <iframe className="h-[90vh] lg:min-w-[500px] rounded-xl" src="https://forms.clickup.com/9015161037/f/8cngw6d-5175/H2YNTWSYSZIDQ99N7B"></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadsStep3b2;
