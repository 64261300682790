function CustomersStep2b() {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Obecny Klient</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Obecny Klient - Dostarczenie materiałów
        </p>
        <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic max-w-[800px] mx-auto">
          Materiały może Pani/Pan przesłać na adres e-mail hello@ifil.pl. Gdy
          materiały dotrą to opiekun projektu potwierdzi ten fakt mailowo w
          przeciągu kilku godzin. Materiały można przesłać mailem, bądź za
          pomocą dowolnej usługi chmurowej takiej jak WeTransfer, Dysk Google
          lub OneDrive na adres hello@ifil.pl
        </p>
      </div>
    </>
  );
}

export default CustomersStep2b;
