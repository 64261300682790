import { useStep } from "../../../context/StepProvider";

function CooperationStep1() {
  const { setStep } = useStep();

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2 className="text-4xl text-white">Współpraca z iFil</h2>
        <p className="px-12 py-2 rounded-xl gradient-mixed text-white text-center">
          Nowe połączenie - Współpraca z iFil
        </p>
        {/* <p className="px-12 py-6 border border-white rounded-xl border-opacity-10 text-white italic">
          Wiadomość
        </p> */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12">
          <div className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Dołączenie do Programu Partnerskiego
            </p>
            <p className="text-white text-sm opacity-50 italic mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              "Stawki programu partnerskiego ustalane są indywidualnie.
              Przeważnie są one w okolicach 15%, proszę o przesłanie maila
              hello@ifil.pl z informacją o Kliencie, który potrzebuje naszych
              usług"
            </p>
          </div>
          <div onClick={() => setStep("cooperationStep2")} className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Firma Widoczni
            </p>
            <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
              Wybierz
            </p>
          </div>
          <div className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20">
            <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              Nawiązanie współpracy partnerskiej z iFil
            </p>
            <p className="text-white text-sm opacity-50 italic mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
              "Dziękuję bardzo, proszę o przesłanie informacji na maila
              hello@ifil.pl. Osoba odpowiedzialna za programy partnerskie
              skontaktuje się z Panią/Panem."
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CooperationStep1;
