import { useStep } from "../../context/StepProvider";

function Step0() {
  const { setStep } = useStep();

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-4xl text-white mb-6 lg:mb-12">Nowe połączenie</h2>
      <p className="border border-white rounded-xl border-opacity-10 text-white italic p-4">
        Dzień dobry, iFil Group, jak mogę pomóc?
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-4 mt-6 lg:mt-12 gap-6 lg:gap-12">
        <div
          onClick={() => setStep("customersStep1")}
          className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20"
        >
          <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
            Obecny Klient
          </p>
          <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
            Wybierz
          </p>
        </div>
        <div
          onClick={() => setStep("cooperationStep1")}
          className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20"
        >
          <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
            Współpraca z iFil
          </p>
          <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
            Wybierz
          </p>
        </div>
        <div
          onClick={() => setStep("hrStep1")}
          className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20"
        >
          <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
            Praktyki / Praca
          </p>
          <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
            Wybierz
          </p>
        </div>
        <div
          onClick={() => setStep("leadsStep1")}
          className="group cursor-pointer transition duration-300 ease-linear hover:bg-white flex flex-col items-center justify-center p-6 lg:p-12 border border-white rounded-xl border-opacity-10 text-white bg-gradient-to-br from-white/5 to-white/20"
        >
          <p className="text-white text-2xl font-semibold group-hover:text-violet-800 transition duration-300 ease-linear">
            Potencjalny Klient
          </p>
          <p className="text-white text-sm opacity-50 mt-2 font-semibold group-hover:text-violet-800 transition duration-300 ease-linear uppercase">
            Wybierz
          </p>
        </div>
      </div>
    </div>
  );
}

export default Step0;
